import { IQuestion } from "@sp-crm/core";
import { produce } from "immer";
import { Action } from "redux";
import { Actions } from "../actions";

export interface SettingsState {
    legacyQuestions: IQuestion[];
    legacyQuestionsLoaded: boolean;
}

export function settingsReducer(
    originalState: SettingsState,
    action: Action,
): SettingsState {
    if (!originalState || action.type === Actions[Actions.LOGOUT]) {
        originalState = {
            legacyQuestions: [],
            legacyQuestionsLoaded: false,
        };
    }

    return produce(originalState, state => {
        if (action.type === Actions[Actions.LOAD_SETTINGS_START]) {
            state.legacyQuestions = [];
            state.legacyQuestionsLoaded = false;
        } else if (action.type === Actions[Actions.LOAD_SETTINGS_FINISH]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            state.legacyQuestions = (action as any).settings;
            state.legacyQuestionsLoaded = true;
        } else if (action.type === Actions[Actions.UPDATE_SETTINGS_FINISH]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const updatedQuestion = (action as any).question as IQuestion;
            const foundIndex = state.legacyQuestions.findIndex(
                q => q.id === updatedQuestion.id,
            );
            if (foundIndex >= 0) {
                state.legacyQuestions[foundIndex] = updatedQuestion;
            } else {
                state.legacyQuestions.push(updatedQuestion);
            }
        } else if (action.type === Actions[Actions.DELETE_SETTING_FINISH]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const questionId = (action as any).questionId as string;
            state.legacyQuestions = state.legacyQuestions.filter(
                q => q.id !== questionId,
            );
        }

        if (
            action.type === Actions[Actions.UPDATE_SETTINGS_FINISH] ||
            action.type === Actions[Actions.LOAD_SETTINGS_FINISH]
        ) {
            state.legacyQuestions = state.legacyQuestions
                .slice()
                .sort((a, b) => (a.order || 0) - (b.order || 0));
        }
    });
}
