import { Action } from "redux";
import { Actions } from "../actions";

export interface NotificationState {
    showUserSaveSuccess: boolean;
    showTenantSaveSuccess: boolean;
}

export function notificationReducer(
    state: NotificationState,
    action: Action,
): NotificationState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            showUserSaveSuccess: false,
            showTenantSaveSuccess: false,
        };
    }

    if (action.type === Actions[Actions.UPDATE_USER_FINISH]) {
        state = { ...state, showUserSaveSuccess: true };
    }

    if (action.type === Actions[Actions.UPDATE_TENANT_FINISH]) {
        state = { ...state, showTenantSaveSuccess: true };
    }

    if (action.type === Actions[Actions.CLEAR_USER_SAVE_NOTIFICATION]) {
        state = { ...state, showUserSaveSuccess: false };
    }

    if (action.type === Actions[Actions.CLEAR_TENANT_SAVE_NOTIFICATION]) {
        state = { ...state, showTenantSaveSuccess: false };
    }

    return state;
}
