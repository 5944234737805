import * as C from "@sp-crm/core";
import { FileEntityId, parseEntityId } from "@sp-crm/core";
import { produce } from "immer";
import * as _ from "lodash";
import { Action } from "redux";
import { LoadingState } from "../../constants/loading";
import { Actions } from "../actions";
import {
    ClearFileCacheAction,
    LoadFilesAction,
    UpdateFileAction,
} from "../actions/files";

export interface FilesState {
    files: { [entityId: FileEntityId]: C.IFile[] };
    loading: { [entityId: FileEntityId]: LoadingState };
}

export function filesReducer(originalState: FilesState, action: Action): FilesState {
    if (!originalState || action.type === Actions[Actions.LOGOUT]) {
        originalState = {
            files: {},
            loading: {},
        };
    }
    return produce(originalState, state => {
        if (action.type === Actions[Actions.LOAD_FILES_FINISH]) {
            const castAction = action as LoadFilesAction;
            const entityId = parseEntityId<FileEntityId>(castAction.entityId);
            state.files[entityId] = _.map(castAction.files, C.File.load);
            state.loading[entityId] = LoadingState.DONE;
        }

        if (action.type === Actions[Actions.LOAD_FILES_START]) {
            const castAction = action as LoadFilesAction;
            const entityId = parseEntityId<FileEntityId>(castAction.entityId);
            state.loading[entityId] = LoadingState.LOADING;
        }

        if (action.type === Actions[Actions.UPDATE_FILE_FINISH]) {
            const castAction = action as UpdateFileAction;
            const newFile = C.File.load(castAction.file);
            const entityId = parseEntityId<FileEntityId>(newFile.entityId);
            const existingSet = state.files[entityId];
            if (existingSet) {
                // TODO pivot this collection and use IDs to refer instead
                const without = _.reject(existingSet, f => f.id === newFile.id);
                if (!newFile.deleted) {
                    without.push(newFile);
                }
                state.files[entityId] = without;
            }
        }

        if (action.type === Actions[Actions.CLEAR_FILE_CACHE]) {
            const castAction = action as ClearFileCacheAction;
            const entityId = parseEntityId<FileEntityId>(castAction.entityId);
            delete state.files[entityId];
            delete state.loading[entityId];
        }
    });
}
