import { WhiteLabelContentMode } from "generated/graphql";
import React, { useEffect, useState } from "react";
import {
    ProductBranding,
    useProductBranding,
    useWhiteLabelContentMode,
} from "store/selectors/branding";
import { setFavicon, setPageTitle } from "util/browser";

interface ProductBrandingContainerProps {
    children: React.ReactNode | ((branding: ProductBranding) => React.ReactNode);
}

interface HasBeacon {
    Beacon?: (action: string, config?: unknown) => void;
}

export const ProductBrandingContainer: React.FC<
    ProductBrandingContainerProps
> = props => {
    const { children } = props;
    const { branding } = useProductBranding();
    const contentMode = useWhiteLabelContentMode();
    const [beaconInitialized, setBeaconInitialized] = useState(false);

    useEffect(() => {
        if (branding) {
            setFavicon(branding.faviconUrl);
            setPageTitle(branding.productName);
        }
    }, [branding]);

    useEffect(() => {
        const Beacon = (window as HasBeacon).Beacon;

        if (Beacon) {
            if (contentMode === WhiteLabelContentMode.Full && !beaconInitialized) {
                Beacon("init", "16c639c8-f76a-413e-9a18-5efab05bd76c");
                setBeaconInitialized(true);
            } else if (beaconInitialized && contentMode !== WhiteLabelContentMode.Full) {
                Beacon("destroy");
                setBeaconInitialized(false);
            }
        }
    }, [contentMode]); // eslint-disable-line react-hooks/exhaustive-deps

    if (typeof children === "function") {
        return <>{children(branding)}</>;
    }

    return <>{children}</>;
};
