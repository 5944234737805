import { RegionState } from "store/reducers/region";
import { getBrowserValue, setBrowserValue } from "./browser";

const crmRegionKey = "CRM_REGION";

export const getInitialRegionState = (): RegionState => {
    const regionFromStorage = getBrowserValue(crmRegionKey);
    if (regionFromStorage) {
        return {
            selectedRegion: regionFromStorage,
            regions: [],
            explicitRegionSelected: true,
        };
    }
    return {
        selectedRegion: null,
        regions: [],
        explicitRegionSelected: false,
    };
};

export const trackRegion = (regionState: RegionState) => {
    if (regionState.selectedRegion && regionState.explicitRegionSelected) {
        setBrowserValue(crmRegionKey, regionState.selectedRegion);
    }
};
