import { EVERYONE_SENTINEL, UserId } from "@sp-crm/core";
import { Select } from "components/ui/select";
import { useGetUsersForRegionQuery } from "generated/graphql";
import React from "react";
import { useCurrentUserId, useRegionId } from "store/selectors/hooks";
import { stableQueryOptions } from "util/requests";

export type SelectableUser = UserId | typeof EVERYONE_SENTINEL | undefined | null | "";

interface UserSelect {
    label: string | JSX.Element;
    onChange: (userId: SelectableUser) => void;
    value: SelectableUser;
    includeEveryone: boolean;
    everyoneLabel?: string;
    includeUnassigned?: boolean;
    unassignedLabel?: string;
    annotateMe?: boolean;
}

export const UserSelect: React.FC<UserSelect> = props => {
    const {
        label,
        includeEveryone,
        includeUnassigned,
        unassignedLabel,
        everyoneLabel,
        value,
        onChange,
    } = props;
    const regionId = useRegionId();
    const userId = useCurrentUserId();
    const users = useGetUsersForRegionQuery(
        { regionId },
        { ...stableQueryOptions(), enabled: !!regionId },
    );
    const annotateMe = props.annotateMe ?? true;
    if (!users.isSuccess || !users.data) {
        return (
            <Select label={label} disabled>
                <option>Loading...</option>
            </Select>
        );
    }
    const sortedUsers = users.data.getUsersForRegion.sort((a, b) =>
        a.preferredName
            .toLocaleLowerCase()
            .localeCompare(b.preferredName.toLocaleLowerCase()),
    );
    const availableUsers = sortedUsers.filter(u => !u.hidden || u.id === value);
    const activeUsers: Record<string, string> = {};
    const disabledUsers: Record<string, string> = {};
    availableUsers.forEach(u => {
        const actualName =
            annotateMe && u.id === userId ? `${u.preferredName} (me)` : u.preferredName;
        if (u.disabled) {
            disabledUsers[u.id] = actualName;
        } else {
            activeUsers[u.id] = actualName;
        }
    });
    return (
        <Select
            label={label}
            value={value}
            onChange={v =>
                onChange(v.target.value as UserId | typeof EVERYONE_SENTINEL | "")
            }>
            {includeEveryone ? (
                <option value={EVERYONE_SENTINEL}>{everyoneLabel ?? "Everyone"}</option>
            ) : null}
            {includeUnassigned ? (
                <option value="">{unassignedLabel ?? "Unassigned"}</option>
            ) : null}
            {Object.keys(disabledUsers).length > 0 ? (
                <>
                    <optgroup label="Active Users">
                        {Object.entries(activeUsers).map(([id, name]) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </optgroup>
                    <optgroup label="Inactive Users">
                        {Object.entries(disabledUsers).map(([id, name]) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                    </optgroup>
                </>
            ) : (
                <>
                    {Object.entries(activeUsers).map(([id, name]) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </>
            )}
        </Select>
    );
};
