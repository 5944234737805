import { User, UserId } from "@sp-crm/core";
import { Action } from "redux";
import { Actions } from "../actions";

export interface UserState {
    users: Record<UserId, User>;
    usersLoadedOnce: boolean;
}

export function usersReducer(state: UserState, action: Action): UserState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            users: {},
            usersLoadedOnce: false,
        };
    }

    if (action.type === Actions[Actions.LOAD_ALL_USERS_END]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const users: any[] = (action as any).users;
        const newUsersObj: { [id: string]: User } = {};
        users.forEach(u => {
            newUsersObj[u.id] = User.load(u);
        });
        state = { ...state, users: newUsersObj, usersLoadedOnce: true };
    }

    if (action.type === Actions[Actions.UPDATE_USER_FINISH]) {
        const newUsersObj = { ...state.users };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const payload: User = (action as any).user;
        newUsersObj[payload.id] = payload;
        state = { ...state, users: newUsersObj };
    }

    return state;
}
