import * as C from "@sp-crm/core";
import { InternalId } from "@sp-crm/core";
import { Action } from "redux";
import { LoadingState } from "../../constants/loading";
import { Actions } from "../actions";

export interface TemplateState {
    templates: { [id: string]: C.IEmailTemplate };
    loadingState: LoadingState;
}

export function templateReducer(state: TemplateState, action: Action): TemplateState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            templates: {},
            loadingState: LoadingState.NOTSTARTED,
        };
    }

    if (action.type === Actions[Actions.LOAD_ALL_EMAILTEMPLATES_START]) {
        state = { ...state, loadingState: LoadingState.LOADING };
    }

    if (
        action.type === Actions[Actions.SAVE_NEW_EMAILTEMPLATE_FINISH] ||
        action.type === Actions[Actions.UPDATE_EMAILTEMPLATE_FINISH]
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const template: C.IEmailTemplate = (action as any).template;
        const newTemplateObj: { [id: string]: C.IEmailTemplate } = {};
        newTemplateObj[template.id] = C.EmailTemplate.load(template);
        return Object.assign({}, state, {
            templates: Object.assign(state.templates, newTemplateObj),
        });
    }

    if (action.type === Actions[Actions.DELETE_EMAILTEMPLATE_FINISH]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const templateId: InternalId = (action as any).emailTemplateId;
        const newTemplateList = state.templates;
        delete newTemplateList[templateId];
        return Object.assign({}, state, {
            templates: newTemplateList,
        });
    }

    if (action.type === Actions[Actions.LOAD_ALL_EMAILTEMPLATES_FINISH]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const templates: C.IEmailTemplate[] = (action as any).templates;
        const newTemplateObj: { [id: string]: C.IEmailTemplate } = {};
        templates.forEach(template => {
            newTemplateObj[template.id] = C.EmailTemplate.load(template);
        });
        return Object.assign({}, state, {
            templates: newTemplateObj,
            loadingState: LoadingState.DONE,
        });
    }

    return state;
}
