import { ClientId, ClientReactivateResponse, UserId } from "@sp-crm/core";
import { Draft, produce } from "immer";
import { Action } from "redux";
import { CommunityOrdering } from "../../generated/graphql";
import { Actions } from "../actions";

type RawClient = { id: ClientId; version: number } & Record<string, unknown>;

export interface ClientState {
    clients: Record<ClientId, RawClient>;
    fullyLoadedClients: ClientId[];
    count: number;
    loading: string[];
    selectedUserId: UserId;
    loadAllRun: boolean;
}

const updateClientInState = (
    currentState: ClientState,
    rawClient: RawClient & { deleted?: boolean },
) =>
    produce(currentState, (draft: Draft<ClientState>) => {
        if (rawClient.deleted) {
            delete draft.clients[rawClient.id];
        } else {
            const existingClient = draft.clients[rawClient.id];
            if (
                existingClient &&
                rawClient.version &&
                (existingClient.version as number) > (rawClient.version as number)
            ) {
                // Ignore the stale data to handle the race condition.
                return;
            }
            draft.clients[rawClient.id] = rawClient;
            if (!draft.fullyLoadedClients.includes(rawClient.id)) {
                draft.fullyLoadedClients.push(rawClient.id);
            }
            draft.count = Object.keys(draft.clients).length;
        }
    });

export function clientsReducer(state: ClientState, action: Action): ClientState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            fullyLoadedClients: [],
            clients: {},
            count: 0,
            loading: [],
            selectedUserId: null,
            loadAllRun: false,
        };
    }

    const isSingleClientAction =
        action.type === Actions[Actions.UPDATE_CLIENT_FINISH] ||
        action.type === Actions[Actions.LOAD_CLIENT_FINISH] ||
        action.type === Actions[Actions.SAVE_CLIENT_NEW_CONTACT_PERSON_FINISH] ||
        action.type === Actions[Actions.UPDATE_CLIENT_CONTACT_PERSON_FINISH] ||
        action.type === Actions[Actions.DELETE_CLIENT_CONTACT_PERSON_FINISH] ||
        action.type === Actions[Actions.ADD_COMMUNITY_TO_CLIENT_FINISH] ||
        action.type === Actions[Actions.REMOVE_COMMUNITY_FROM_CLIENT_FINISH] ||
        action.type === Actions[Actions.EXCLUDE_COMMUNITY_FROM_CLIENT_FINISH] ||
        action.type === Actions[Actions.UNEXCLUDE_COMMUNITY_FROM_CLIENT_FINISH] ||
        action.type === Actions[Actions.ADD_NO_COMPARE_FROM_CLIENT_FINISH] ||
        action.type === Actions[Actions.REMOVE_NO_COMPARE_FROM_CLIENT_FINISH] ||
        action.type === Actions[Actions.SAVE_NEW_CLIENT_FINISH];

    if (isSingleClientAction) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        state = updateClientInState(state, (action as any).client);
    }

    if (action.type === Actions[Actions.REACTIVATE_CLIENT_FINISH]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const body: ClientReactivateResponse = (action as any).data;
        state = updateClientInState(state, body.newClient as RawClient);
        state = updateClientInState(state, body.oldClient as RawClient);
    }

    if (action.type === Actions[Actions.LOAD_CLIENTS_FOR_COMMUNITY_FINISH]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const clients: RawClient[] = (action as any).clients;

        state = produce(state, (draft: Draft<ClientState>) => {
            clients.forEach(c => {
                if (!draft.fullyLoadedClients.includes(c.id)) {
                    draft.clients[c.id] = c;
                }
            });
            draft.count = Object.keys(draft.clients).length;
        });
    }

    if (action.type === Actions[Actions.LOAD_CLIENT_FINISH]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const rawClient: RawClient = (action as any).client;
        const clientId = rawClient.id;

        state = produce(state, (draft: Draft<ClientState>) => {
            draft.loading = draft.loading.filter(x => x !== clientId);
        });
    }

    if (action.type === Actions[Actions.LOAD_CLIENT_START]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const clientId = (action as any).clientId;

        state = produce(state, (draft: Draft<ClientState>) => {
            draft.loading.push(clientId);
        });
    }

    if (action.type === Actions[Actions.CLIENTS_LIST_SELECT_USER]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const newSelectedUserId = (action as any).userId;

        state = produce(state, (draft: Draft<ClientState>) => {
            draft.selectedUserId = newSelectedUserId;
        });
    }

    if (action.type === Actions[Actions.UPDATE_CLIENT_COMMUNITY_ORDER]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        state = handleUpdateClientCommunityOrder(state, action as any);
    }

    return state;
}

function handleUpdateClientCommunityOrder(
    state: ClientState,
    action: {
        clientId: ClientId;
        ordering: CommunityOrdering[];
    },
) {
    return produce(state, (draft: Draft<ClientState>) => {
        const existingClient = draft.clients[action.clientId];

        if (!existingClient || !Array.isArray(existingClient.clientCommunities)) {
            return;
        }

        existingClient.clientCommunities.forEach(cc => {
            const matchingOrder = action.ordering.find(
                o => o.communityId === cc.community.id,
            );
            if (matchingOrder) {
                cc.order = matchingOrder.order;
            }
        });
    });
}
