import { Action } from "redux";
import { Actions } from "../actions";

export interface EulaState {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
    userPayload: any;
}

export function eulaReducer(state: EulaState, action: Action): EulaState {
    if (!state) {
        state = {
            userPayload: null,
        };
    }

    if (action.type === Actions[Actions.EULA_TRANSITION]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        return Object.assign({}, state, { userPayload: (action as any).userPayload });
    }

    return state;
}
