import { ITenant, Maybe, RunningFeatures, Tenant } from "@sp-crm/core";
import { Action } from "redux";
import { Actions } from "../actions";

function isFeatureEnabled(tenant: ITenant, featureName: RunningFeatures): boolean {
    return (
        Maybe.fromValue(tenant)
            .map(t => t.features)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            .map(f => (f as any)[featureName])
            .getOrElse(false)
    );
}

export interface TenantState {
    tenant: ITenant;
    features: {
        emailoptout: boolean;
    };
}

export function tenantReducer(state: TenantState, action: Action): TenantState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            tenant: null,
            features: {
                emailoptout: false,
            },
        };
    }

    if (
        action.type === Actions[Actions.LOAD_TENANT_FINISH] ||
        action.type === Actions[Actions.UPDATE_TENANT_FINISH]
    ) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const loadedTenant = Tenant.fromDocument((action as any).tenant);
        state = {
            ...state,
            tenant: loadedTenant,
            features: {
                emailoptout: isFeatureEnabled(loadedTenant, "emailoptout"),
            },
        };
    }

    return state;
}
