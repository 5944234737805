import * as C from "@sp-crm/core";
import {
    ILocation,
    LocalizedDistance,
    nullEntityId,
    RegionId,
    SettingsCommunitySortType,
} from "@sp-crm/core";
import {
    ISearchAnswer,
    ISearchPrice,
    ISearchRating,
} from "components/community-search/search-bar/util";
import { produce } from "immer";
import { Action } from "redux";
import {
    CommunitySearchAction,
    CommunitySearchGoToPageAction,
    CommunitySearchInitializeAction,
    CommunitySearchMapMoveAction,
    CommunitySearchSetPageSizeAction,
    CommunitySearchUpdateSearchAction,
} from "store/actions/communities";
import { Actions } from "../actions";

export interface CommunitySearchInstance {
    coordinates?: number[];
    isGeocoding: boolean;
    searchText: string;
    onlyZip: boolean;
    maxDistance?: LocalizedDistance;
    address?: string;
    state?: string;
    city?: string;
    zip?: string;
    county?: string;
    advancedAnswers: ISearchAnswer[];
    showAll: boolean;
    perPage: number;
    pageNumber: number; // 0-indexed
    /**
     * If true, the search will request as many hits as possible and
     * all pagination will be done client-side.
     *
     * This is useful for the map view, where we want to show all hits
     */
    clientSidePaginationOnly: boolean;
    sort: C.CommunitySortOptions;
    bothGenders: boolean;
    maleOnly: boolean;
    femaleOnly: boolean;
    genderNotSet: boolean;
    contractNoState: boolean;
    contractPending: boolean;
    contractActive: boolean;
    contractOneOff: boolean;
    contractNone: boolean;
    contractTerminated: boolean;
    licensePending: boolean;
    licenseActive: boolean;
    licenseOnProbation: boolean;
    licenseClosed: boolean;
    licenseNotApplicable: boolean;
    licenseNotSet: boolean;
    minCapacity?: number;
    maxCapacity?: number;
    rating: ISearchRating;
    price?: ISearchPrice;
    specificZip?: boolean;
    specificCity?: boolean;
    specificCounty?: boolean;
    regionId: RegionId;
    hideDontShow: boolean;
    matchLowScore?: number;
    matchHighScore?: number;
    matchRule?: string;
    freeMapCoordinates?: {
        northWest: ILocation;
        southEast: ILocation;
    };
}

export interface CommunitySearchState {
    searches: { [key: string]: CommunitySearchInstance };
}

export function buildDefaultSearchInstance(
    regionId: RegionId,
    defaultCommunitySortOrder: SettingsCommunitySortType,
): CommunitySearchInstance {
    return {
        isGeocoding: false,
        searchText: "",
        onlyZip: true,
        showAll: false,
        perPage: 25,
        pageNumber: 0,
        clientSidePaginationOnly: false,
        sort: defaultCommunitySortOrder,
        advancedAnswers: [],
        bothGenders: false,
        maleOnly: false,
        femaleOnly: false,
        genderNotSet: false,
        contractNoState: false,
        contractPending: false,
        contractActive: false,
        contractOneOff: false,
        contractNone: false,
        contractTerminated: false,
        licensePending: false,
        licenseActive: false,
        licenseOnProbation: false,
        licenseClosed: false,
        licenseNotApplicable: false,
        licenseNotSet: false,
        minCapacity: null,
        maxCapacity: null,
        rating: { value: 0, includeUnrated: true },
        regionId,
        hideDontShow: false,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function coerceTypes(rawSearchInstance: any): CommunitySearchInstance {
    const base: CommunitySearchInstance = Object.assign(
        buildDefaultSearchInstance(nullEntityId<RegionId>(), "name"),
        rawSearchInstance,
    );
    if (typeof base.maxDistance !== "number") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        base.maxDistance = parseInt(base.maxDistance as any, 10) as LocalizedDistance;
    }
    return base;
}

export function communitySearchReducer(
    state: CommunitySearchState,
    action: Action,
): CommunitySearchState {
    if (
        !state ||
        action.type === Actions[Actions.LOGOUT] ||
        action.type === Actions[Actions.SELECT_REGION]
    ) {
        state = {
            searches: {},
        };
    }

    if (action.type === Actions[Actions.COMMUNITY_SEARCH_CLEAR]) {
        const castAction = action as CommunitySearchInitializeAction;
        const newSearch: CommunitySearchInstance = buildDefaultSearchInstance(
            castAction.regionId,
            castAction.sort,
        );
        const newSearchObj: { [key: string]: CommunitySearchInstance } = {};
        newSearchObj[castAction.key] = newSearch;
        const newStateObj = Object.assign({}, state.searches, newSearchObj);
        state = Object.assign({}, state, { searches: newStateObj });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_INITIALIZE]) {
        const castAction = action as CommunitySearchInitializeAction;
        state = produce(state, draft => {
            const searchKey = castAction.key;
            if (!draft.searches[searchKey]) {
                if (castAction.searchInstance) {
                    draft.searches[searchKey] = castAction.searchInstance;
                } else {
                    draft.searches[searchKey] = buildDefaultSearchInstance(
                        castAction.regionId,
                        castAction.sort,
                    );
                }
            }
        });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_UPDATE_SEARCH]) {
        const castAction = action as CommunitySearchUpdateSearchAction;
        state = produce(state, draft => {
            const searchKey = castAction.key;
            if (draft.searches[searchKey]) {
                draft.searches[searchKey] = {
                    ...coerceTypes(castAction.searchInstance),
                    freeMapCoordinates: draft.searches[searchKey].freeMapCoordinates,
                    perPage: draft.searches[searchKey].perPage,
                };
            } else {
                draft.searches[searchKey] = coerceTypes(castAction.searchInstance);
            }
        });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_MAP_MOVE]) {
        const castAction = action as CommunitySearchMapMoveAction;
        state = produce(state, (draft: CommunitySearchState) => {
            if (draft && draft.searches && draft.searches[castAction.key]) {
                draft.searches[castAction.key].freeMapCoordinates = {
                    northWest: castAction.northWest,
                    southEast: castAction.southEast,
                };
                draft.searches[castAction.key].clientSidePaginationOnly = true;
                draft.searches[castAction.key].pageNumber = 0;
            }
        });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_MAP_RESET]) {
        const castAction = action as CommunitySearchAction;
        state = produce(state, (draft: CommunitySearchState) => {
            if (draft && draft.searches && draft.searches[castAction.key]) {
                delete draft.searches[castAction.key].freeMapCoordinates;
                draft.searches[castAction.key].clientSidePaginationOnly = false;
            }
        });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_NEXT_PAGE]) {
        const castAction = action as CommunitySearchAction;
        state = produce(state, (draft: CommunitySearchState) => {
            if (draft && draft.searches && draft.searches[castAction.key]) {
                draft.searches[castAction.key].pageNumber =
                    draft.searches[castAction.key].pageNumber + 1;
            }
        });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_PREV_PAGE]) {
        const castAction = action as CommunitySearchAction;
        state = produce(state, (draft: CommunitySearchState) => {
            if (draft && draft.searches && draft.searches[castAction.key]) {
                draft.searches[castAction.key].pageNumber =
                    draft.searches[castAction.key].pageNumber - 1;
            }
        });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_GO_TO_PAGE]) {
        const castAction = action as CommunitySearchGoToPageAction;
        state = produce(state, (draft: CommunitySearchState) => {
            if (draft && draft.searches && draft.searches[castAction.key]) {
                draft.searches[castAction.key].pageNumber = castAction.pageNumber;
            }
        });
    } else if (action.type === Actions[Actions.COMMUNITY_SEARCH_SET_PAGE_SIZE]) {
        const castAction = action as CommunitySearchSetPageSizeAction;
        state = produce(state, (draft: CommunitySearchState) => {
            if (draft && draft.searches && draft.searches[castAction.key]) {
                draft.searches[castAction.key].perPage = castAction.pageSize;
            }
        });
    }

    return state;
}
