import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IsoTimestamp, UserId } from "@sp-crm/core";
import { GetTaskTypesQuery } from "generated/graphql";
import { TaskOwnerViewableKind } from "../../constants/tasks";

export const TasksAll = "ALL";
export const TasksActive = "ACTIVE";
export const TasksComplete = "COMPLETE";
export type TaskStateFilter = typeof TasksAll | typeof TasksActive | typeof TasksComplete;
export type TaskType = GetTaskTypesQuery["getTaskTypes"][0];
export const allTaskTypesSentinel = "_all";
export const unsetTaskTypeSentinel = "_unset";
export type FilterableTaskTypes =
    | TaskType["id"]
    | typeof allTaskTypesSentinel
    | typeof unsetTaskTypeSentinel;

interface TaskToplevelFilterState {
    selectedUserId: UserId | null;
    query: string;
    state: TaskStateFilter;
    entity: TaskOwnerViewableKind;
    taskType: FilterableTaskTypes;
    completedAtTimestamp?: IsoTimestamp;
    sectionVisibility: {
        overdue: boolean;
        today: boolean;
        tomorrow: boolean;
        future: boolean;
        none: boolean;
    };
}
const initialState: TaskToplevelFilterState = {
    selectedUserId: null,
    query: "",
    state: TasksActive,
    entity: "ALL",
    taskType: allTaskTypesSentinel,
    sectionVisibility: {
        overdue: true,
        today: true,
        tomorrow: true,
        future: true,
        none: true,
    },
};

export const taskDashboardSlice = createSlice({
    name: "taskToplevel",
    initialState,
    reducers: {
        selectUser: (state, action: PayloadAction<UserId | null>) => {
            state.selectedUserId = action.payload;
        },
        updateQuery: (state, action: PayloadAction<string>) => {
            state.query = action.payload;
        },
        updateStatusFilter: (state, action: PayloadAction<TaskStateFilter>) => {
            state.state = action.payload;
        },
        updateEntityFilter: (state, action: PayloadAction<TaskOwnerViewableKind>) => {
            state.entity = action.payload;
        },
        updateTypeFilter: (state, action: PayloadAction<FilterableTaskTypes>) => {
            state.taskType = action.payload;
        },
        firstLoad: (state, action: PayloadAction<IsoTimestamp>) => {
            if (!state.completedAtTimestamp) {
                state.completedAtTimestamp = action.payload;
            }
        },
        clearCompleted: (state, action: PayloadAction<IsoTimestamp>) => {
            state.completedAtTimestamp = action.payload;
        },
        toggleSectionVisibility: (
            state,
            action: PayloadAction<keyof TaskToplevelFilterState["sectionVisibility"]>,
        ) => {
            state.sectionVisibility[action.payload] =
                !state.sectionVisibility[action.payload];
        },
    },
});
