export interface SelectBaseOption {
    value: string;
    text: string;
}

export interface SelectHeaderOption {
    header: string; // if this ever changes, we need to update isSelectProHeaderOption below!!
    groupOptions: SelectBaseOption[];
}

export type SelectProOption = SelectBaseOption | SelectHeaderOption;

export const isSelectProHeaderOption = (
    option: SelectProOption,
): option is SelectHeaderOption => {
    // Need to do this Object.prototype.hasOwnProperty.call to avoid this compiler error:
    // "Do not access Object.prototype method 'hasOwnProperty' from target object"
    return Object.prototype.hasOwnProperty.call(option, "header");
};
