import React from "react";
import { SelectProOption } from "../../helpers/select-defs";
import { generateOptionsJSX } from "../../helpers/select-util";
import { Select } from "./select";

interface Props {
    label?: string | JSX.Element;
    options: SelectProOption[];
    includePlaceholderOption?: boolean; // insert a placeholder "(select)" option
    placeholderStr?: string; // "(select)" will be used by default, unless this is provided
}

export const SelectPro: React.FC<
    Props & React.SelectHTMLAttributes<HTMLSelectElement>
> = props => {
    const { options, includePlaceholderOption, placeholderStr, ...rest } = props;
    const selectOptions = React.useMemo(() => {
        return generateOptionsJSX(options, includePlaceholderOption, placeholderStr);
    }, [options, includePlaceholderOption, placeholderStr]);

    return (
        <div className={"w-full"}>
            <Select {...rest}>{selectOptions}</Select>
        </div>
    );
};
