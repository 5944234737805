import { Action } from "redux";
import { Actions } from "../actions";

export enum ResponsiveMode {
    small = 0,
    medium = 1,
    large = 2,
    xLarge = 3,
    xxLarge = 4,
    xxxLarge = 5,
}

export interface ResponsiveState {
    showMenu: boolean;
    mode: ResponsiveMode;
}

export function responsiveReducer(
    state: ResponsiveState,
    action: Action,
): ResponsiveState {
    if (!state) {
        state = {
            mode: ResponsiveMode.small,
            showMenu: false,
        };
    }
    if (action.type === Actions[Actions.UPDATE_SIZE]) {
        state = {
            ...state,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            mode: (action as any).mode,
        };
    }
    if (action.type === Actions[Actions.HIDE_MENU]) {
        state = {
            ...state,
            showMenu: false,
        };
    }
    if (action.type === Actions[Actions.SHOW_MENU]) {
        state = {
            ...state,
            showMenu: true,
        };
    }

    return state;
}
