import { Action } from "redux";
import { Actions } from "../actions";

export interface ISetPasswordState {
    inProgress: boolean;
    isSuccessful: boolean;
    isFailed: boolean;
}

export function setPasswordReducer(
    state: ISetPasswordState,
    action: Action,
): ISetPasswordState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        return {
            inProgress: false,
            isSuccessful: false,
            isFailed: false,
        };
    }

    switch (action.type) {
        case Actions[Actions.SET_PASSWORD_START]:
            return {
                ...state,
                ...{
                    inProgress: true,
                    isSuccessful: false,
                    isFailed: false,
                },
            };
        case Actions[Actions.SET_PASSWORD_SUCCESS]:
            return {
                ...state,
                ...{
                    inProgress: false,
                    isSuccessful: true,
                    isFailed: false,
                },
            };
        case Actions[Actions.SET_PASSWORD_FAILED]:
            return {
                ...state,
                ...{
                    inProgress: false,
                    isSuccessful: false,
                    isFailed: true,
                },
            };
        default:
            return state;
    }
}
