import { Action } from "redux";
import { Actions } from "../actions";

// TODO!: Define type EmailState
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
type EmailState = any;

export function emailReducer(state: EmailState, action: Action): EmailState {
    if (!state || action.type === Actions[Actions.EMAIL_FORM_INIT]) {
        state = {
            isValid: false,
            isSubmitted: false,
            errors: [],
            form: {
                content: "",
                recipients: [],
                sendCopyToAuthor: true,
                subject: "",
                extraData: {
                    includeCommunityComparisonPdf: false,
                    includeCommunityPdf: false,
                    includeReferralPdf: false,
                },
            },
        };
    }

    if (action.type === Actions[Actions.EMAIL_FORM_SUBMIT]) {
        state = {
            ...state,
            isSubmitted: true,
        };
    }
    if (action.type === Actions[Actions.EMAIL_FORM_ERROR]) {
        state = {
            ...state,
            isValid: false,
            isSubmitted: false,
        };
    }
    if (action.type === Actions[Actions.EMAIL_FORM_SUCCESS]) {
        state = {
            ...state,
            isValid: true,
            isSubmitted: false,
        };
    }

    if (action.type === Actions[Actions.EMAIL_FORM_VALUE_CHANGE]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const field: string = (action as any).update.field;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const value: any = (action as any).update.value;

        state = {
            ...state,
        };

        if (field.includes("root.")) {
            const rootField = field.replace("root.", "");

            state[rootField] = value;
        } else if (field.includes("extra.")) {
            const rootField = field.replace("extra.", "");

            state.form.extraData[rootField] = value;
        } else {
            state.form[field] = value;
        }
    }

    if (action.type === Actions[Actions.EMAIL_FORM_VALUE_CLEAR]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const fields: string[] = (action as any).update.value;

        state = {
            ...state,
        };

        fields.forEach(field => {
            if (field.includes("root.")) {
                const rootField = field.replace("root.", "");

                delete state[rootField];
            } else if (field.includes("extra.")) {
                const rootField = field.replace("extra.", "");

                delete state.form.extraData[rootField];
            } else {
                delete state.form[field];
            }
        });
    }

    if (action.type === Actions[Actions.EMAIL_FORM_TEMPLATE_INIT]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const template: any = (action as any).update.template;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const user: any = (action as any).update.user;

        state = {
            ...state,
        };

        if (template === "template_referral") {
            state.form.subject = user.referralEmailSubj;
            state.form.content = user.referralEmailBody;
        } else {
            state.form.subject = user.commInfoEmailSubj;
            state.form.content = user.commInfoEmailBody;
        }
    }

    return state;
}
