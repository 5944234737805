import { User, UserId } from "@sp-crm/core";
import { Action } from "redux";
import * as browser from "../../util/browser";
import { Actions } from "../actions";

export interface SingleUserState {
    loggedIn: boolean;
    userId: UserId;
    user: User | null;
}

function buildState(): SingleUserState {
    const userId = browser.getUserId();
    const loggedIn = userId && userId !== "" ? true : false;
    const state: SingleUserState = {
        loggedIn,
        userId,
        user: null,
    };
    return state;
}

export function singleUserReducer(
    state: SingleUserState,
    action: Action,
): SingleUserState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = buildState();
    }

    if (
        action.type === Actions[Actions.LOGIN_START] ||
        action.type === Actions[Actions.LOGIN_FINISH] ||
        action.type === Actions[Actions.LOGOUT]
    ) {
        state = buildState();
    }
    if (action.type === Actions[Actions.LOAD_PERMISSIONS_FINISH]) {
        state = {
            loggedIn: true,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            userId: (action as any).data.user.id,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            user: (action as any).data.user,
        };
    }

    return state;
}
