import { Action } from "redux";
import { Actions } from "../actions";

export interface TimeState {
    now: Date;
}

export function timeReducer(state: TimeState, action: Action): TimeState {
    if (!state) {
        state = {
            now: null,
        };
    }

    if (action.type === Actions[Actions.TICK]) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        const current: Date = (action as any).now;
        state = { ...state, now: current };
    }

    return state;
}
