import {
    IBridgeFieldMetadata,
    ILocation,
    IQuestion,
    ITenantSettings,
    Question,
    defaultTenantSettings,
} from "@sp-crm/core";
import { GuestComparisonPayload, GuestLayoutSection } from "components/guest/types";
import { produce } from "immer";
import { Action } from "redux";
import { Actions } from "../actions";

export interface GuestInformationState {
    tenantLogo?: string;
    sharingUserDisplayName?: string;
    clientName?: string;
    showCommunitySummaryOnComparisonChart: boolean;
    showCommunityBaseAndHighPrice: boolean;
    showCommunitySecondPersonFee: boolean;
    showCommunityPetDeposit: boolean;
    showCommunityFee: boolean;
    includeFinances: boolean;
    loaded: boolean; // a bit silly, there's probably a better way to get this done.
    communityNotes: Record<string, string>;
    layout: GuestLayoutSection[] | null;
    baseLocation: ILocation | null;
    locale: string;
    entityFields: IBridgeFieldMetadata[] | null;
    tenantSettings: ITenantSettings;
    questions: IQuestion[];
}
export function guestInformationReducer(
    originalState: GuestInformationState,
    action: Action,
): GuestInformationState {
    if (!originalState) {
        originalState = {
            tenantLogo: null,
            sharingUserDisplayName: null,
            clientName: null,
            showCommunitySummaryOnComparisonChart: false,
            showCommunityBaseAndHighPrice: false,
            showCommunitySecondPersonFee: false,
            showCommunityPetDeposit: false,
            showCommunityFee: false,
            includeFinances: false,
            loaded: false,
            communityNotes: {},
            layout: null,
            baseLocation: null,
            locale: "default",
            entityFields: null,
            tenantSettings: defaultTenantSettings(),
            questions: [],
        };
    }

    return produce(originalState, state => {
        if (action.type === Actions[Actions.LOAD_GUEST_COMMUNITY_COMPARISON_FINISH]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const castAction = (action as any).data as GuestComparisonPayload;
            state.clientName = castAction.clientName;
            state.tenantLogo = castAction.logo;
            state.sharingUserDisplayName = castAction.sharingUser;
            state.showCommunitySummaryOnComparisonChart =
                castAction.tenantSettings.showCommunitySummaryOnComparisonChart;
            state.showCommunityBaseAndHighPrice =
                castAction.tenantSettings.showCommunityBaseAndHighPrice;
            state.showCommunitySecondPersonFee =
                castAction.tenantSettings.showCommunitySecondPersonFee;
            state.showCommunityPetDeposit =
                castAction.tenantSettings.showCommunityPetDeposit;
            state.showCommunityFee = castAction.tenantSettings.showCommunityFee;
            state.includeFinances = castAction.includeFinances;
            state.loaded = true;
            state.communityNotes = castAction.communityNotes;
            state.layout = castAction.layout;
            state.baseLocation = castAction.baseLocation;
            state.locale = castAction.locale;
            state.entityFields = castAction.entityFields;
            state.questions = castAction.questions?.map(q => Question.load(q)) || [];
            state.tenantSettings = {
                ...originalState.tenantSettings,
                ...castAction.tenantSettings,
            };
        }
    });
}
