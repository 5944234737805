import { CommunityId } from "@sp-crm/core";
import { produce } from "immer";
import { Action } from "redux";
import { Actions } from "../actions";

type RawCommunity = { id: CommunityId; version: number } & Record<string, unknown>;

export interface CommunityState {
    communities: Record<CommunityId, RawCommunity>;
    count: number;
}

export function communitiesReducer(
    originalState: CommunityState,
    action: Action,
): CommunityState {
    if (!originalState || action.type === Actions[Actions.LOGOUT]) {
        originalState = {
            communities: {},
            count: 0,
        };
    }
    return produce(originalState, state => {
        if (
            action.type === Actions[Actions.UPDATE_COMMUNITY_FINISH] ||
            action.type === Actions[Actions.LOAD_COMMUNITY_FINISH] ||
            action.type === Actions[Actions.SAVE_NEW_COMMUNITY_CONTACT_FINISH] ||
            action.type === Actions[Actions.UPDATE_COMMUNITY_CONTACT_FINISH] ||
            action.type === Actions[Actions.SAVE_NEW_COMMUNITY_FINISH] ||
            action.type === Actions[Actions.DELETE_COMMUNITY_CONTACT_FINISH] ||
            action.type === Actions[Actions.SAVE_NEW_COMMUNITY_PHOTOS_FINISH] ||
            action.type === Actions[Actions.UPDATE_COMMUNITY_PHOTOS_FINISH] ||
            action.type === Actions[Actions.DELETE_COMMUNITY_PHOTOS_FINISH]
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            if ((action as any).community.deleted) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                delete state.communities[(action as any).community.id];
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
                const rawCommunity: RawCommunity = (action as any).community;
                const existingCommunity = state.communities[rawCommunity.id];
                if (
                    existingCommunity &&
                    existingCommunity.version > rawCommunity.version
                ) {
                    // handle race condition.
                    // If a fast request got back to us before a slow request for the same client,
                    // ignore the stale data.
                } else {
                    state.communities[rawCommunity.id] = rawCommunity;
                }
                state.count = Object.keys(state.communities).length;
            }
        }

        if (
            action.type === Actions[Actions.LOAD_COMMUNITIES_PAGE_FINISH] ||
            action.type === Actions[Actions.LOAD_COMMUNITIES_BY_CLIENT_FINISH]
        ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const communities: RawCommunity[] = (action as any).communities;
            communities.forEach(c => {
                state.communities[c.id] = c;
            });
        }
    });
}
