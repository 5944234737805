import { EVERYONE_SENTINEL, Tenant, UserId, UserPreferences } from "@sp-crm/core";
import { ClientSearchSort } from "generated/graphql";
import { produce } from "immer";
import { Action } from "redux";
import { ClientSearchType } from "../../components/clients/client-search/client-search";
import { Actions, DashboardSortAction } from "../actions";

export const CardSortDropdownValues = [
    { value: ClientSearchSort.UpdatedAtDesc, text: "Updated date (most recent)" },
    { value: ClientSearchSort.UpdatedAtAsc, text: "Updated date (oldest)" },
    { value: ClientSearchSort.TaskDueAsc, text: "Task due date (most urgent)" },
    { value: ClientSearchSort.TaskDueDesc, text: "Task due date (least urgent)" },
    { value: ClientSearchSort.NameAsc, text: "Name (A-Z)" },
    { value: ClientSearchSort.NameDesc, text: "Name (Z-A)" },
    { value: ClientSearchSort.BestContactAsc, text: "Best contact (A-Z)" },
    { value: ClientSearchSort.BestContactDesc, text: "Best contact (Z-A)" },
    { value: ClientSearchSort.CreatedAtAsc, text: "Created date (oldest)" },
    { value: ClientSearchSort.CreatedAtDesc, text: "Created date (most recent)" },
];

export interface ClientSearchInstance {
    searchType: ClientSearchType;
    searchText: string;
    selectedUserId: UserId | typeof EVERYONE_SENTINEL;
    referralSourceUserId: UserId | typeof EVERYONE_SENTINEL;
    cardSortPreference: ClientSearchSort;
    searchBySerialEnabled: boolean;
}

export interface ClientSearchState {
    clientSearchInstance: ClientSearchInstance;
}

export function clientSearchReducer(
    originalState: ClientSearchState,
    action: Action,
): ClientSearchState {
    if (
        !originalState ||
        action.type === Actions[Actions.LOGOUT] ||
        action.type === Actions[Actions.SELECT_REGION]
    ) {
        originalState = {
            clientSearchInstance: {
                searchType: ClientSearchType.CoreNames,
                searchText: "",
                selectedUserId: EVERYONE_SENTINEL,
                referralSourceUserId: EVERYONE_SENTINEL,
                cardSortPreference: ClientSearchSort.UpdatedAtDesc,
                searchBySerialEnabled: false,
            },
        };
    }

    return produce(originalState, state => {
        if (action.type === Actions[Actions.LOAD_TENANT_FINISH]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const loadedTenant = Tenant.fromDocument((action as any).tenant);
            state.clientSearchInstance.searchBySerialEnabled =
                loadedTenant.settings.showClientSerial;
        }
        if (action.type === Actions[Actions.LOAD_PERMISSIONS_FINISH]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            const user = (action as any).data.user;

            const preferences: UserPreferences = user.preferences;

            if (preferences?.preferredClientAssignmentFilter === "everyone") {
                state.clientSearchInstance.selectedUserId = EVERYONE_SENTINEL;
            } else {
                state.clientSearchInstance.selectedUserId = user.id;
            }
        }
        if (action.type === Actions[Actions.CLIENTS_LIST_SELECT_USER]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            state.clientSearchInstance.selectedUserId = (action as any).userId;
        }
        if (action.type === Actions[Actions.CLIENTS_LIST_SELECT_REFERRAL_USER]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            state.clientSearchInstance.referralSourceUserId = (action as any).userId;
        }
        if (action.type === Actions[Actions.CLIENT_SEARCH_TEXT]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            state.clientSearchInstance.searchText = (action as any).searchText;
        }
        if (action.type === Actions[Actions.CLIENT_SEARCH_TYPE]) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
            state.clientSearchInstance.searchType = (action as any).searchType;
        }
        if (action.type === Actions[Actions.DASHBOARD_SORT]) {
            state.clientSearchInstance.cardSortPreference = (
                action as DashboardSortAction
            ).cardSort;
        }
    });
}
