import { ContactEntityId, ContactId } from "@sp-crm/core";
import { Action } from "redux";
import { Actions } from "../actions";

export interface SelectedContactState {
    selections: { [id: ContactEntityId]: ContactId };
}

export function selectedContactReducer(
    state: SelectedContactState,
    action: Action,
): SelectedContactState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            selections: {},
        };
    }

    if (action.type === Actions[Actions.SELECT_CONTACT]) {
        state = { ...state, selections: { ...state.selections } };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        state.selections[(action as any).parentId] = (action as any).contactId;
    }

    if (action.type === Actions[Actions.SAVE_NEW_COMMUNITY_CONTACT_FINISH]) {
        state = { ...state, selections: { ...state.selections } };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        state.selections[(action as any).community.id] = (action as any).newContactId;
    }

    if (action.type === Actions[Actions.DELETE_COMMUNITY_SELECTED_CONTACT]) {
        state = { ...state, selections: { ...state.selections } };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        state.selections[(action as any).communityId] = null;
    }

    if (action.type === Actions[Actions.SAVE_CLIENT_NEW_CONTACT_PERSON_FINISH]) {
        // NOTE: For clients, this isn't really the "selected" contact, but it's the
        // most recently added contact. This newContactId is not guaranteed to exist
        // on the client if it is deleted after being the most recently created contact
        state = { ...state, selections: { ...state.selections } };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
        state.selections[(action as any).client.id] = (action as any).newContactId;
    }

    return state;
}
