import { Action } from "redux";
import { Actions } from "../actions";

export interface LoginState {
    loggedIn: boolean;
    loginFailure: boolean;
    loginInProgress: boolean;
}

export function loginReducer(state: LoginState, action: Action): LoginState {
    if (!state || action.type === Actions[Actions.LOGOUT]) {
        state = {
            loginFailure: false,
            loggedIn: false,
            loginInProgress: false,
        };
    }

    if (action.type === Actions[Actions.LOGIN_START]) {
        state = {
            ...state,
            loginInProgress: true,
        };
    }
    if (action.type === Actions[Actions.LOGIN_FINISH]) {
        state = {
            ...state,
            loggedIn: true,
        };
    }
    if (action.type === Actions[Actions.LOGIN_FAIL]) {
        state = {
            ...state,
            loginFailure: true,
        };
    }

    return state;
}
