import React from "react";
import {
    isSelectProHeaderOption,
    SelectBaseOption,
    SelectHeaderOption,
    SelectProOption,
} from "./select-defs";

export const generateOptionsJSX = (
    options: SelectProOption[],
    includePlaceholderOption = false,
    placeholderStr = "(select)",
): JSX.Element[] => {
    // helper to create the option group JSX
    const genOptGroup = (
        currentOptGroupLabel: string,
        innerOptionsJSX: JSX.Element[],
    ) => {
        return (
            <optgroup
                key={`OptGroup_${currentOptGroupLabel}`}
                label={currentOptGroupLabel}>
                {innerOptionsJSX}
            </optgroup>
        );
    };

    // helper to create JSX for a standard option
    const genOption = (option: SelectBaseOption) => {
        return (
            <option key={option.value} value={option.value}>
                {option.text}
            </option>
        );
    };

    // Insert the placeholder option if desired, otherwise start with empty
    const optionsJSX: JSX.Element[] = includePlaceholderOption
        ? [
              <option key={""} value={""}>
                  {placeholderStr}
              </option>,
          ]
        : [];

    // Loop through every SelectBaseOption | SelectHeaderOption
    options.forEach(option => {
        if (isSelectProHeaderOption(option)) {
            // This is the OptGroup case
            const innerOptionsJSX: JSX.Element[] = [];

            // Generate the JSX for each child option
            (option as SelectHeaderOption).groupOptions.forEach(childOption => {
                innerOptionsJSX.push(genOption(childOption));
            });

            optionsJSX.push(
                genOptGroup((option as SelectHeaderOption).header, innerOptionsJSX),
            );
        } else {
            // This is just a regular old option
            optionsJSX.push(genOption(option as SelectBaseOption));
        }
    });

    return optionsJSX;
};
