import * as C from "@sp-crm/core";
import { ReferralId } from "@sp-crm/core";
import { Action } from "redux";
import { LoadingState } from "../../constants/loading";
import { Actions } from "../actions";

export interface ReferralsState {
    referrals: { [referralId: ReferralId]: C.IReferral };
    editingReferral: string;
    loading: { [entityId: ReferralId]: LoadingState };
}

export function referralsReducer(
    originalState: ReferralsState,
    action: Action,
): ReferralsState {
    if (!originalState || action.type === Actions[Actions.LOGOUT]) {
        originalState = {
            referrals: {},
            editingReferral: null,
            loading: {},
        };
    }
    return originalState;
}
