import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FileEntityId } from "@sp-crm/core";

interface FilesDashboardState {
    [key: string]: {
        query: string;
    };
}
const initialState: FilesDashboardState = {};

export const filesDashboardSlice = createSlice({
    name: "filesDashboard",
    initialState,
    reducers: {
        updateQuery: (state, action: PayloadAction<[FileEntityId, string]>) => {
            const [fileId, query] = action.payload;
            state[fileId] = {
                query,
            };
        },
    },
});
